<script setup lang="ts">
import { UseImage } from "@vueuse/components";
import type { UseImageOptions } from "@vueuse/core";
import type { ImgHTMLAttributes } from "vue";

interface IImageProps extends /** @vue-ignore */ Omit<UseImageOptions, "src"> {
  failedSrc?: string;
  placeholderSrc?: string;
  src?: UseImageOptions["src"];
}

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(defineProps<IImageProps>(), {
  failedSrc: IMAGE_FAILED_URL,
  placeholderSrc: IMAGE_PLACEHOLDER_URL,
  src: IMAGE_PLACEHOLDER_URL,
});

const { failedSrc, placeholderSrc, ...innerProps } = props;

const attrs = useAttrs();
</script>

<template>
  <ClientOnly>
    <UseImage v-bind="{ ...attrs, ...innerProps }">
      <template #loading>
        <img :src="placeholderSrc" alt="Загрузка" :class="attrs.class">
      </template>
      <template #error>
        <img :src="failedSrc" alt="Ошибка" :class="attrs.class">
      </template>
    </UseImage>
    <template #fallback>
      <img
        v-bind="attrs"
        :class="innerProps.class || attrs.class"
        :alt="innerProps.alt"
        :crossorigin="innerProps.crossorigin as ImgHTMLAttributes['crossorigin']"
        :loading="innerProps.loading"
        :referrerpolicy="innerProps.referrerPolicy as ImgHTMLAttributes['referrerpolicy']"
        :sizes="innerProps.sizes"
        :src="innerProps.src"
        :srcset="innerProps.srcset"
      >
    </template>
  </ClientOnly>
</template>
